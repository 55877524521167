%delete-indicator {
    opacity: 0.5;
    text-decoration: line-through;

    &:hover {
        opacity: 1;
    }
}

.card {
    &.user-card {
        &.deleted {
            @extend %delete-indicator;
        }
    }
}

.training-stats {
    .list-group-item {
        &.deleted {
            @extend %delete-indicator;
        }
    }
}
