@import "bootstrap";
@import "font";
@import "keyframes";
@import "~hamburgers/_sass/hamburgers/hamburgers";

// tags
//----------------------------------------------------------------------------------------------------------------------
html {
    font-size: 16px;
    min-height: 100%;
}

body {
    background-color: rgba(0, 0, 0, .9);
    background-image: url("../images/background.png");
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
}

button {
    outline: none !important;
}

// classes
//----------------------------------------------------------------------------------------------------------------------
.btn-white {
    border: 1px solid $white;
    background-color: rgba($white, .2);
}

.btn-outline-white {
    border: 1px solid $white;
    border-radius: 0;
    background-color: transparent;

    &:hover {
        background-color: rgba($white, .2);
    }
}

.page-title {
    margin-bottom: 2rem;

    h3 {
        margin: 0;
    }

    > .row {
        align-items: center;
    }
}

.toast-wrapper {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
}

.filter {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
}

.checkbox-slider {
    height: 26px;
    background: $gray-400;
    position: relative;
    border-radius: 50px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.5), 0 1px 0 rgba(255,255,255,0.2);

    &:after {
        content: 'Nein';
        color: #000;
        position: absolute;
        right: 10px;
        z-index: 0;
        font: 12px/26px Arial, sans-serif;
        font-weight: bold;
        text-shadow: 1px 1px 0 rgba(255,255,255,.15);
    }

    &:before {
        content: 'Ja';
        color: $success;
        position: absolute;
        left: 10px;
        z-index: 0;
        font: 12px/26px Arial, sans-serif;
        font-weight: bold;
    }

    label {
        display: block;
        width: 34px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        top: 3px;
        left: 3px;
        z-index: 1;
        background: #fcfff4;
        background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        border-radius: 50px;
        transition: all 0.4s ease;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.3);
    }

    input[type=checkbox] {
        visibility: hidden;
        &:checked + label {
            left: calc(100% - 37px);
        }
    }
}

.border-right-0 {
    border-right: 0;
}

.static-pages-wrapper {
    position: absolute;
    bottom: 25px;

    &.login {
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    a {
        color: $white;
    }
}

// bootstrap overwrites
//----------------------------------------------------------------------------------------------------------------------

// buttons
.btn,
.btn-lg {
    text-transform: uppercase;
}

.btn-primary {
    border-color: $secondary;
}

// cards
.card {
    background: linear-gradient(180deg, rgba(240, 240, 240, 1) 60%, rgba(206, 206, 206, 1) 100%);
    //margin-bottom: 1.5rem;
    height: 100%;
    box-shadow: $box-shadow-sm;
}

.card-title {
    color: $primary;
    margin-bottom: 0;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.card-body + .card-body {
    padding-top: 0;
}

.card-body {
    .alert {
        padding: .5rem;
    }
}

// modals
.modal-header {
    color: $primary;
}

.modal-body {
    color: $black;
}

// toasts
.toast-body {
    color: $black;
}

// spinners
.spinner-grow {
    margin: 100px auto;

    button[type=submit] & {
        margin: 0;
        display: none;
    }

    button[type=submit].loading & {
        display: inline-block;

        ~ i {
            display: none;
        }
    }
}

// dropdowns
.dropdown-toggle {
    border-radius: 0;
}

// list
.list-group-item {
    color: $black;

    .form-group {
        margin-bottom: 0;
    }
}

// login form
//----------------------------------------------------------------------------------------------------------------------
.login-form {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, calc(-50% - 100px));
    border: 1px solid $white;
    border-radius: $border-radius;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(112, 112, 112, 0) 100%);
    padding: 2.8rem;
    width: 830px;
    max-width: 100%;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 50px;

    .logo {
        margin-top: -11rem;
    }

    h1 {
        text-transform: uppercase;
        text-shadow: $text-shadow;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .forgot-password {
        text-align: left;
    }

    .input-group-text {
        color: $white;
        border-color: $white;
        background-color: rgba($white, .2);
    }

    .form-control {
        color: $white;
        border-color: $white;
        background-color: transparent;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-animation: autofill 0s forwards;
        animation: autofill 0s forwards;
    }

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: $white;
    }

    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: $white;
        opacity: 1;
    }

    ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: $white;
        opacity: 1;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $white;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: $white;
    }

    ::placeholder { /* Most modern browsers support this now. */
        color: $white;
    }
}

// wrapper
//----------------------------------------------------------------------------------------------------------------------
.wrapper {
    display: flex;
    align-items: stretch;

    .content {
        width: 100%;
        overflow-y: auto;
        max-height: 100vh;

        &:before {
            content: '';
            display: block;
            height: 4.3rem;
            background-color: rgba($black, .5);
            margin-bottom: 1rem;
        }
    }
}

// sidebar
//----------------------------------------------------------------------------------------------------------------------
.sidebar {
    background-color: rgba($black, .3);
    width: 280px;
    padding: 2rem;
    min-height: 100vh;
    position: absolute;
    margin-left: -280px;
    transition: all .3s ease-in;
    z-index: 1;

    &.active {
        margin-left: 0;
        background-color: rgba($black, .93);

        .hamburger {
            transform: translateX(-1rem);
        }
    }

    .hamburger {
        position: absolute;
        right: 0;
        top: 1rem;
        transform: translateX(calc(100% + 1rem));
        color: $white;
        display: flex;
        align-items: center;
        transition: transform .3s;
    }

    .logo {
        margin-bottom: 2rem;
    }

    .actions {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    hr {
        border-color: $white;
    }

    > ul.nav {
        margin-left: -2rem;
        margin-right: -2rem;

        .nav-link {
            padding-left: 2rem;
            padding-right: 2rem;
            color: $white;

            &.active,
            &:hover {
                background-color: $secondary;
                color: $black;
                font-weight: $font-weight-bold;
            }
        }
    }
}

// widget
//----------------------------------------------------------------------------------------------------------------------
.widget {
    background-color: rgba($black, 0.3);
    padding: 1.1rem;
    margin-bottom: 1rem;

    .form-group {
        margin: 0;
    }
}

// led
//----------------------------------------------------------------------------------------------------------------------
.led {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: inline-block;
    position: relative;

    &::before {
        content: '';
        width: 5px;
        height: 2px;
        background-color: rgba($white, .69);
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 3px;
        transform: translateX(-50%);
        box-shadow: 0 0 10px 3px $white;
    }
}

.led-sm {
    width: 12px;
    height: 12px;
}

@each $color, $value in $theme-colors {
    .led-#{$color} {
        background-color: lighten($value, 15%);
        box-shadow: inset 0 0 1px $black;
    }
}

// slide card
//----------------------------------------------------------------------------------------------------------------------
.slide-card {
    .card-body {
        display: flex;
        align-items: flex-end;
    }
}

// dropzone
//----------------------------------------------------------------------------------------------------------------------
#slides-dropzone.dropzone {
    background: rgba($white, .2);
    border-color: $white;
    display: none;
}

#videos-dropzone.dropzone {
    background: rgba($white, .2);
    border-color: $white;
    display: none;
}

// viewport width > 576px
//----------------------------------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
    html {
        font-size: 20px;
    }
}

// viewport width > 992px
//----------------------------------------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
    .wrapper {
        .content {
            padding-top: 2rem;

            &:before {
                display: none;
            }
        }
    }

    .sidebar {
        margin-left: 0;
        position: relative;
        width: 350px;

        .hamburger {
            display: none;
        }
    }

    .border-right-lg {
        border-right: 1px solid $border-color !important;
    }
}

@import 'admin_users';
